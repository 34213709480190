/* eslint-disable import/no-extraneous-dependencies */
import { GroupedMenuOption, GroupedOption, OptionProp, MenuOptionProp } from '@dv01-inc/waterfall-ui'

export const optionsArray: OptionProp[] = [
  {
    id: 2,
    value: 'Acerola',
    label: 'Acerola',
    dataTestid: 'Acerola-test',
  },
  {
    id: 6,
    value: 'Ambarella',
    label: 'Ambarella',
    dataTestid: 'Ambarella-test',
    isDisabled: true,
  },
  {
    id: 8,
    value: 'Apricot',
    label: 'Apricot',
    dataTestid: 'Apricot-test',
  },
  {
    id: 9,
    value: 'Apple',
    label: 'Apple Apple Apple Apple',
    dataTestid: 'Apple-test',
  },
  {
    id: 10,
    value: 'Beach plum',
    label: 'Beach plum',
    dataTestid: 'Beach plum-test',
  },
  {
    id: 11,
    value: 'Bignay',
    label: 'Bignay',
    dataTestid: 'Bignay-test',
  },
  {
    id: 12,
    value: 'Blueberry',
    label: 'Blueberry',
    dataTestid: 'Blueberry-test',
  },
  {
    id: 13,
    value: 'Banana',
    label: 'Banana',
    dataTestid: 'Banana-test',
  },
  {
    id: 14,
    value: 'Currant',
    label: 'Currant',
    dataTestid: 'Currant-test',
  },
  {
    id: 15,
    value: 'Cherry',
    label: 'Cherry',
    dataTestid: 'Cherry-test',
  },
  {
    id: 16,
    value: 'Cranberry',
    label: 'Cranberry',
    dataTestid: 'Cranberry-test',
  },
]

export const groupedOptionsArray: GroupedOption[] = [
  {
    label: 'A Fruits',
    options: [
      {
        id: 2,
        value: 'Acerola',
        label: 'Acerola',
        dataTestid: 'Acerola-test',
      },
      {
        id: 6,
        value: 'Ambarella',
        label: 'Ambarella',
        dataTestid: 'Ambarella-test',
        isDisabled: true,
      },
      {
        id: 8,
        value: 'Apricot',
        label: 'Apricot',
        dataTestid: 'Apricot-test',
      },
      {
        id: 9,
        value: 'Apple',
        label: 'Apple',
        dataTestid: 'Apple-test',
      },
    ],
  },
  {
    label: 'B Fruits',
    options: [
      {
        id: 10,
        value: 'Beach plum',
        label: 'Beach plum',
        dataTestid: 'Beach plum-test',
      },
      {
        id: 11,
        value: 'Bignay',
        label: 'Bignay',
        dataTestid: 'Bignay-test',
      },
      {
        id: 12,
        value: 'Blueberry',
        label: 'Blueberry',
        dataTestid: 'Blueberry-test',
      },
      {
        id: 13,
        value: 'Banana',
        label: 'Banana',
        dataTestid: 'Banana-test',
      },
    ],
  },
  {
    label: 'C Fruits',
    options: [
      {
        id: 14,
        value: 'Currant',
        label: 'Currant',
        dataTestid: 'Currant-test',
      },
      {
        id: 15,
        value: 'Cherry',
        label: 'Cherry',
        dataTestid: 'Cherry-test',
      },
      {
        id: 16,
        value: 'Cranberry',
        label: 'Cranberry',
        dataTestid: 'Cranberry-test',
      },
    ],
  },
]

export const menuOptionsArray: MenuOptionProp[] = [
  {
    id: 2,
    value: 'Acerola',
    label: 'Acerola',
    dataTestid: 'Acerola-test',
    onClick: () => alert('button clicked'),
    icon: {
      name: 'th-list',
      variant: 'primary',
    },
  },
  {
    id: 6,
    value: 'Ambarella',
    label: 'Ambarella',
    onClick: () => alert('button clicked'),
    dataTestid: 'Ambarella-test',
    isDisabled: true,
    icon: {
      name: 'analytics',
      variant: 'primary',
    },
  },
  {
    id: 8,
    value: 'Apricot',
    label: 'Apricot',
    onClick: () => alert('button clicked'),
    dataTestid: 'Apricot-test',
    icon: {
      name: 'flask',
      variant: 'primary',
    },
  },
  {
    id: 9,
    value: 'Apple',
    label: 'Apple',
    onClick: () => alert('button clicked'),
    dataTestid: 'Apple-test',
    icon: {
      name: 'clock',
      variant: 'primary',
    },
  },
]

export const groupedMenuOptionsArray: GroupedMenuOption[] = [
  {
    options: [
      {
        id: 2,
        value: 'Acerola',
        label: 'Acerola',
        dataTestid: 'Acerola-test',
        onClick: () => alert('button clicked'),
        icon: {
          name: 'th-list',
          variant: 'primary',
        },
      },
      {
        id: 6,
        value: 'Ambarella',
        label: 'Ambarella',
        onClick: () => alert('button clicked'),
        dataTestid: 'Ambarella-test',
        isDisabled: true,
        icon: {
          name: 'analytics',
          variant: 'primary',
        },
      },
      {
        id: 8,
        value: 'Apricot',
        label: 'Apricot',
        onClick: () => alert('button clicked'),
        dataTestid: 'Apricot-test',
        icon: {
          name: 'flask',
          variant: 'primary',
        },
      },
      {
        id: 9,
        value: 'Apple',
        label: 'Apple',
        onClick: () => alert('button clicked'),
        dataTestid: 'Apple-test',
        icon: {
          name: 'clock',
          variant: 'primary',
        },
      },
    ],
  },
  {
    options: [
      {
        id: 10,
        value: 'Beach plum',
        label: 'Beach plum',
        onClick: () => alert('button clicked'),
        dataTestid: 'Beach plum-test',
        icon: {
          name: 'book',
          variant: 'primary',
        },
      },
      {
        id: 11,
        value: 'Bignay',
        label: 'Bignay',
        onClick: () => alert('button clicked'),
        dataTestid: 'Bignay-test',
        icon: {
          name: 'pen',
          variant: 'primary',
        },
      },
      {
        id: 12,
        value: 'Blueberry',
        label: 'Blueberry',
        onClick: () => alert('button clicked'),
        dataTestid: 'Blueberry-test',
        icon: {
          name: 'upload',
          variant: 'primary',
        },
      },
      {
        id: 13,
        value: 'Banana',
        label: 'Banana',
        onClick: () => alert('button clicked'),
        dataTestid: 'Banana-test',
        icon: {
          name: 'trash',
          variant: 'primary',
        },
      },
    ],
  },
  {
    options: [
      {
        id: 14,
        value: 'Currant',
        label: 'Currant',
        onClick: () => alert('button clicked'),
        dataTestid: 'Currant-test',
        icon: {
          name: 'user-secret',
          variant: 'primary',
        },
      },
      {
        id: 15,
        value: 'Cherry',
        label: 'Cherry',
        onClick: () => alert('button clicked'),
        dataTestid: 'Cherry-test',
        icon: {
          name: 'database',
          variant: 'primary',
        },
      },
      {
        id: 16,
        value: 'Cranberry',
        label: 'Cranberry',
        onClick: () => alert('button clicked'),
        dataTestid: 'Cranberry-test',
        icon: {
          name: 'book',
          variant: 'primary',
        },
      },
    ],
  },
  {
    options: [
      {
        id: 20,
        value: 'createNewItem',
        label: 'Create New Item',
        onClick: () => alert('button clicked'),
        dataTestid: 'ctaTest',
        cta: true,
        icon: {
          name: 'plus-circle',
        },
      },
    ],
  },
]
