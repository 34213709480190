exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-chip-mdx": () => import("./../../../src/pages/components/chip.mdx" /* webpackChunkName: "component---src-pages-components-chip-mdx" */),
  "component---src-pages-components-combobox-mdx": () => import("./../../../src/pages/components/combobox.mdx" /* webpackChunkName: "component---src-pages-components-combobox-mdx" */),
  "component---src-pages-components-icon-accessibility-mdx": () => import("./../../../src/pages/components/icon/accessibility.mdx" /* webpackChunkName: "component---src-pages-components-icon-accessibility-mdx" */),
  "component---src-pages-components-icon-code-mdx": () => import("./../../../src/pages/components/icon/code.mdx" /* webpackChunkName: "component---src-pages-components-icon-code-mdx" */),
  "component---src-pages-components-icon-style-mdx": () => import("./../../../src/pages/components/icon/style.mdx" /* webpackChunkName: "component---src-pages-components-icon-style-mdx" */),
  "component---src-pages-components-icon-usage-mdx": () => import("./../../../src/pages/components/icon/usage.mdx" /* webpackChunkName: "component---src-pages-components-icon-usage-mdx" */),
  "component---src-pages-components-listbox-mdx": () => import("./../../../src/pages/components/listbox.mdx" /* webpackChunkName: "component---src-pages-components-listbox-mdx" */),
  "component---src-pages-components-toast-mdx": () => import("./../../../src/pages/components/toast.mdx" /* webpackChunkName: "component---src-pages-components-toast-mdx" */),
  "component---src-pages-components-tooltip-mdx": () => import("./../../../src/pages/components/tooltip.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-mdx" */),
  "component---src-pages-contributions-mdx": () => import("./../../../src/pages/contributions.mdx" /* webpackChunkName: "component---src-pages-contributions-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-guidelines-color-print-mdx": () => import("./../../../src/pages/guidelines/color/print.mdx" /* webpackChunkName: "component---src-pages-guidelines-color-print-mdx" */),
  "component---src-pages-guidelines-color-web-mdx": () => import("./../../../src/pages/guidelines/color/web.mdx" /* webpackChunkName: "component---src-pages-guidelines-color-web-mdx" */),
  "component---src-pages-guidelines-typography-index-mdx": () => import("./../../../src/pages/guidelines/typography/index.mdx" /* webpackChunkName: "component---src-pages-guidelines-typography-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-test-spacing-audit-mdx": () => import("./../../../src/pages/test/spacing-audit.mdx" /* webpackChunkName: "component---src-pages-test-spacing-audit-mdx" */)
}

