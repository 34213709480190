import styled from 'styled-components'

const TypeFamily = styled.p`
  color: #000;
  font-size: 3rem;
  font-family: ${props => props.mono ? "DecimaMonoProLt, monospace" : "Roboto, sans-serif"};
  line-height: 1.2;
`

export default TypeFamily
