import styled from 'styled-components'

const TypeBase = styled.p`
  font-family: Roboto, sans-serif;
  line-height: 1.2;
  color: ${props => props.highlight ? `#855ffc` : '#000'};
  margin-bottom: 1rem;
`

const TypeScale = styled(TypeBase)`
  font-size: ${props => props.size}rem;
`

export default TypeScale
