import React from 'react'
import styled from 'styled-components'

import tokens from '@dv01-inc/waterfall-design-tokens'

const reds = tokens.color.base.red
const blues = tokens.color.base.blue
const greens = tokens.color.base.green
const oranges = tokens.color.base.orange
const purples = tokens.color.base.purple
const grays = tokens.color.base.gray
const primary = tokens.color.brand.primary
const secondary = tokens.color.brand.secondary
const defaultcolor = tokens.color.brand.default
const alternative = tokens.color.brand.alternative
const danger = tokens.color.brand.danger
const success = tokens.color.brand.success
const warning = tokens.color.brand.warning
const dvReds = tokens.color.dataviz.base.red
const dvBlues = tokens.color.dataviz.base.blue
const dvGreens = tokens.color.dataviz.base.green
const dvOranges = tokens.color.dataviz.base.orange
const dvPurples = tokens.color.dataviz.base.purple
const dvYellows = tokens.color.dataviz.base.yellow
const dvBrowns = tokens.color.dataviz.base.brown
const dvPinks = tokens.color.dataviz.base.pink
const dvAquas = tokens.color.dataviz.base.aqua
const dvTeals = tokens.color.dataviz.base.teal
const dvLimess = tokens.color.dataviz.base.lime
const dvPlums = tokens.color.dataviz.base.plum
const dvBrandReds = tokens.color.dataviz.brand.red
const dvBrandBlues = tokens.color.dataviz.brand.blue
const dvBrandGreens = tokens.color.dataviz.brand.green
const dvBrandOranges = tokens.color.dataviz.brand.orange
const dvBrandPurples = tokens.color.dataviz.brand.purple
const dvBrandYellows = tokens.color.dataviz.brand.yellow
const dvBrandBrowns = tokens.color.dataviz.brand.brown
const dvBrandPinks = tokens.color.dataviz.brand.pink
const dvBrandAquas = tokens.color.dataviz.brand.aqua
const dvBrandTeals = tokens.color.dataviz.brand.teal
const dvBrandLimess = tokens.color.dataviz.brand.lime
const dvBrandPlums = tokens.color.dataviz.brand.plum


const Header = styled.h2`

`

const Swatch = styled.div`
  padding: 15px;
  background-color: ${props => props.color};
  color: ${props => props.typeInverse ? "#fff" : "#000"};
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SwatchCode = styled.div`
  display: flex;
  justify-content: space-between;
`

const SwatchBaseRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr repeat(11, 1fr) 2fr repeat(11, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 2px;
`

const SwatchBrandRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 15px;
`

const SwatchDvBaseRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(22, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 2px;
`

const SwatchDvBrandRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 2px;
`

const ColorSwatch = () => (
  <>
    <SwatchBaseRow>
      <Swatch color={blues[500].value}>
          Blue
          <SwatchCode><span>500</span> <code>{blues[500].value}</code></SwatchCode>
        </Swatch>
      {Object.keys(blues).map((color, i) => {
        return (
        <Swatch color={blues[color].value} typeInverse={(i < 6) ? false : true}>
          <SwatchCode>
            <span>{color} </span>
            <code>{blues[color].value}</code>
            </SwatchCode>
        </Swatch>
        )
      })}
      <Swatch color={reds[500].value}>
        Red
        <SwatchCode><span>500</span> <code>{reds[500].value}</code></SwatchCode>
      </Swatch>
      {Object.keys(reds).map((color, i) => {
        return (
          <Swatch color={reds[color].value} typeInverse={(i < 6) ? false : true}>
            <SwatchCode>
              <span>red{color}</span>
              <code>{reds[color].value}</code>
              </SwatchCode>
          </Swatch>
        )
      })}
      <Swatch color={purples[500].value}>
          Purple
          <SwatchCode>
            <span>500</span>
            <code>{purples[500].value}</code>
          </SwatchCode>
        </Swatch>
      {Object.keys(purples).map((color, i) => {
        return (
          <Swatch color={purples[color].value} typeInverse={(i < 6) ? false : true}>
          <SwatchCode>
            <span>{color}</span>
            <code>{purples[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      <Swatch color={greens[500].value}>
          Green
          <SwatchCode>
            <span>500</span>
            <code>{greens[500].value}</code>
          </SwatchCode>
        </Swatch>
      {Object.keys(greens).map((color, i) => {
        return (
          <Swatch color={greens[color].value} typeInverse={(i < 6) ? false : true}>
          <SwatchCode>
            <span>{color}</span>
            <code>{greens[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      <Swatch color={oranges[500].value}>
          Orange
          <SwatchCode>
            <span>500</span>
            <code>{oranges[500].value}</code>
          </SwatchCode>
        </Swatch>
      {Object.keys(oranges).map((color, i) => {
        return (
          <Swatch color={oranges[color].value} typeInverse={(i < 6) ? false : true}>
            <SwatchCode>
              <span>{color}</span>
              <code>{oranges[color].value}</code>
            </SwatchCode>
        </Swatch>
        )
      })}

      <Swatch color={grays[500].value}>
          Gray
          <SwatchCode>
            <span>500</span>
            <code>{grays[500].value}</code>
          </SwatchCode>
        </Swatch>
      {Object.keys(grays).map((color, i) => {
        return (
          <Swatch color={grays[color].value} typeInverse={(i < 6) ? false : true}>
            <SwatchCode>
              <span>{color}</span>
              <code>{grays[color].value}</code>
            </SwatchCode>
        </Swatch>
        )
      })}
    </SwatchBaseRow>
    <Header>Brand Colors</Header>
    <SwatchBrandRow>
      {Object.keys(primary).map((color, i) => {
        return (
          <Swatch color={primary[color].value} typeInverse={(i < 3) ? false : true}>
            Primary
            <SwatchCode>
              <span>{color}</span>
              <code>{primary[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
      {Object.keys(secondary).map((color, i) => {
        return (
          <Swatch color={secondary[color].value} typeInverse={(i < 3) ? false : true}>
            Secondary
            <SwatchCode>
              <span>{color}</span>
              <code>{secondary[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
      {Object.keys(alternative).map((color, i) => {
        return (
          <Swatch color={alternative[color].value} typeInverse={(i < 3) ? false : true}>
            Alternative
            <SwatchCode>
              <span>{color}</span>
              <code>{alternative[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
      {Object.keys(defaultcolor).map((color, i) => {
        return (
          <Swatch color={defaultcolor[color].value} typeInverse={(i < 3) ? false : true}>
            Default
            <SwatchCode>
              <span>{color}</span>
              <code>{defaultcolor[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
      {Object.keys(success).map((color, i) => {
        return (
          <Swatch color={success[color].value} typeInverse={(i < 3) ? false : true}>
            Success
            <SwatchCode>
              <span>{color}</span>
              <code>{success[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
      {Object.keys(danger).map((color, i) => {
        return (
          <Swatch color={danger[color].value} typeInverse={(i < 3) ? false : true}>
            Danger
            <SwatchCode>
              <span>{color}</span>
              <code>{danger[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
      {Object.keys(warning).map((color, i) => {
        return (
          <Swatch color={warning[color].value} typeInverse={(i < 3) ? false : true}>
            Warning
            <SwatchCode>
              <span>{color}</span>
              <code>{warning[color].value}</code>
            </SwatchCode>
          </Swatch>
        )
      })}
    </SwatchBrandRow>
    <Header>Data Viz Tokens</Header>
    <SwatchDvBaseRow>
      {Object.keys(dvReds).map((color, i) => {
        return (
          <Swatch color={dvReds[color].value} typeInverse={(i < 6) ? false : true}>
          Red
          <SwatchCode>
          <span>{color}</span>
          <code>{dvReds[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvBlues).map((color, i) => {
        return (
          <Swatch color={dvBlues[color].value} typeInverse={(i < 6) ? false : true}>
          Blue
          <SwatchCode>
          <span>{color}</span>
          <code>{dvBlues[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvGreens).map((color, i) => {
        return (
          <Swatch color={dvGreens[color].value} typeInverse={(i < 6) ? false : true}>
          Green
          <SwatchCode>
          <span>{color}</span>
          <code>{dvGreens[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvOranges).map((color, i) => {
        return (
          <Swatch color={dvOranges[color].value} typeInverse={(i < 6) ? false : true}>
          Orange
          <SwatchCode>
          <span>{color}</span>
          <code>{dvOranges[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvPurples).map((color, i) => {
        return (
          <Swatch color={dvPurples[color].value} typeInverse={(i < 6) ? false : true}>
          Purple
          <SwatchCode>
          <span>{color}</span>
          <code>{dvPurples[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvYellows).map((color, i) => {
        return (
          <Swatch color={dvYellows[color].value} typeInverse={(i < 6) ? false : true}>
          Yellow
          <SwatchCode>
          <span>{color}</span>
          <code>{dvYellows[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
    </SwatchDvBaseRow>
    <SwatchDvBaseRow>
      {Object.keys(dvBrowns).map((color, i) => {
        return (
          <Swatch color={dvBrowns[color].value} typeInverse={(i < 6) ? false : true}>
          Brown
          <SwatchCode>
          <span>{color}</span>
          <code>{dvBrowns[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvPinks).map((color, i) => {
        return (
          <Swatch color={dvPinks[color].value} typeInverse={(i < 6) ? false : true}>
          Pink
          <SwatchCode>
          <span>{color}</span>
          <code>{dvPinks[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvAquas).map((color, i) => {
        return (
          <Swatch color={dvAquas[color].value} typeInverse={(i < 6) ? false : true}>
          Aqua
          <SwatchCode>
          <span>{color}</span>
          <code>{dvAquas[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvTeals).map((color, i) => {
        return (
          <Swatch color={dvTeals[color].value} typeInverse={(i < 6) ? false : true}>
          Teal
          <SwatchCode>
          <span>{color}</span>
          <code>{dvTeals[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvLimess).map((color, i) => {
        return (
          <Swatch color={dvLimess[color].value} typeInverse={(i < 6) ? false : true}>
          Lime
          <SwatchCode>
          <span>{color}</span>
          <code>{dvLimess[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
      {Object.keys(dvPlums).map((color, i) => {
        return (
          <Swatch color={dvPlums[color].value} typeInverse={(i < 6) ? false : true}>
          Plum
          <SwatchCode>
          <span>{color}</span>
          <code>{dvPlums[color].value}</code>
          </SwatchCode>
        </Swatch>
        )
      })}
    </SwatchDvBaseRow>
    <Header>DataViz Brand Colors</Header>
    <SwatchDvBrandRow>
      {Object.keys(dvBrandReds).map((color, i) => {
        return (
          <Swatch color={dvBrandReds[color].value} typeInverse>
          red{color}: <br />
          <code>{dvBrandReds[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandPinks).map((color, i) => {
        return (
          <Swatch color={dvBrandPinks[color].value} typeInverse>
          pink{color}: <br />
          <code>{dvBrandPinks[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandBlues).map((color, i) => {
        return (
          <Swatch color={dvBrandBlues[color].value} typeInverse>
          blue{color}: <br />
          <code>{dvBrandBlues[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandAquas).map((color, i) => {
        return (
          <Swatch color={dvBrandAquas[color].value} typeInverse>
          aqua{color}: <br />
          <code>{dvBrandAquas[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandGreens).map((color, i) => {
        return (
          <Swatch color={dvBrandGreens[color].value} typeInverse>
          green{color}: <br />
          <code>{dvBrandGreens[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandTeals).map((color, i) => {
        return (
          <Swatch color={dvBrandTeals[color].value} typeInverse>
          teal{color}: <br />
          <code>{dvBrandTeals[color].value}</code>
        </Swatch>
        )
      })}
    </SwatchDvBrandRow>
    <SwatchDvBrandRow>
      {Object.keys(dvBrandYellows).map((color, i) => {
        return (
          <Swatch color={dvBrandYellows[color].value} typeInverse>
          yellow{color}: <br />
          <code>{dvBrandYellows[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandLimess).map((color, i) => {
        return (
          <Swatch color={dvBrandLimess[color].value} typeInverse>
          lime{color}: <br />
          <code>{dvBrandLimess[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandPurples).map((color, i) => {
        return (
          <Swatch color={dvBrandPurples[color].value} typeInverse>
          purple{color}: <br />
          <code>{dvBrandPurples[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandPlums).map((color, i) => {
        return (
          <Swatch color={dvBrandPlums[color].value} typeInverse>
          plum{color}: <br />
          <code>{dvBrandPlums[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandOranges).map((color, i) => {
        return (
          <Swatch color={dvBrandOranges[color].value} typeInverse>
          orange{color}: <br />
          <code>{dvBrandOranges[color].value}</code>
        </Swatch>
        )
      })}
      {Object.keys(dvBrandBrowns).map((color, i) => {
        return (
          <Swatch color={dvBrandBrowns[color].value} typeInverse>
          brown{color}: <br />
          <code>{dvBrandBrowns[color].value}</code>
        </Swatch>
        )
      })}
    </SwatchDvBrandRow>
  </>
)

export default ColorSwatch
